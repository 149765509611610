export default {
  "tittel.soknader": "Applications",
  "avslutning.advarsel": `Incomplete applications will take longer time to process. It is possible to send documents in later, however, it is recommended to send the application complete with all documents from the start.`,
  "avslutning.sidetittel": `Download the application papers`,
  "avslutning.vedlegg": `Attachment:`,
  "avslutning.sprakvelger.kunettsprak": `The form only exists in`,
  "avslutning.sprakvelger.label": `Choose the language you want for the application papers:`,
  "avslutning.sprakvelger.de": `German`,
  "avslutning.sprakvelger.en": `English`,
  "avslutning.sprakvelger.es": `Spanish`,
  "avslutning.sprakvelger.fr": `French`,
  "avslutning.sprakvelger.nb": `Norwegian (bokmål)`,
  "avslutning.sprakvelger.nn": `Norwegian (nynorsk)`,
  "avslutning.sprakvelger.pl": `Polish`,
  "avslutning.sprakvelger.se": `Northern sami`,
  "avslutning.steg.sjekkbokser.tittel": `Select the attachments you are able to send at this time`,
  "avslutning.steg.sjekkbokser.beskrivelse": `If you do not have all the documents listed below you can forward these later. This may result in your application not being processed until all documents are submitted.`,
  "avslutning.steg.sjekkbokser.markering": `Uncheck any attachments you are unable to send at this time:`,
  "avslutning.steg.lastned.tittel": `Download the application and attachments"`,
  "avslutning.steg.lastned.knapp.ready": `Download`,
  "avslutning.steg.lastned.knapp.loading": `Initiating`,
  "avslutning.steg.lastned.knapp.cover": `Retrieving files`,
  "avslutning.steg.lastned.knapp.merge": `Merging files`,
  "avslutning.steg.lastned.knapp.download": `Downloading`,
  "avslutning.steg.forsteside.tittel": `Download the cover sheet for your application`,
  "avslutning.steg.forsteside.pdf.tittel": `Cover sheet`,
  "avslutning.steg.forsteside.beskrivelse": `The cover sheet contains important information about the <b>NAV unit</b> that will receive the documents. It also contains the address to which the documents should be sent.`,
  "avslutning.steg.forsteside.nedlastingsfeil": `Something went wrong. Make sure you have entered the correct Norwegian national identity number / D-number and try again.`,
  "avslutning.steg.nedlasting.skjema.tittel": `Download the application form {skjemanummer}`,
  "avslutning.steg.nedlasting.skjema.beskrivelse": `The application form must be filled out and signed.`,
  "avslutning.steg.nedlasting.skjema.obs": 'It is important that you use Adobe Acrobat Reader to fill out the form. <br /> <a href="https://get.adobe.com/no/reader/" target="_blank" rel="noopener noreferrer" class="lenke">Download Adobe Acrobat Reader</a> (link opens in a new window).',
  "avslutning.steg.nedlasting.vedlegg.tittel": `Download attachment form`,
  "avslutning.steg.nedlasting.vedlegg.beskrivelse": `The attachment form (s) must be completed and signed.`,
  "avslutning.steg.eksterne.tittel": `You must obtain these documents yourself:`,
  "avslutning.steg.ettersendelse.tittel": `You have stated that these documents will be submitted later:`,
  "avslutning.steg.innsending.tittel": `Send all documents to NAV by post`,
  "avslutning.steg.innsending.beskrivelse": `The documents is to be sent to NAV to the address on the first page.`,
  "avslutning.steg.innsending.obs": `The first page must be at the top of your documents.`,
  "avslutning.steg.videre.tittel": `What happens next?`,
  "avslutning.steg.videre.beskrivelse": `You will hear from oss as soon as we have processed your case. We will be in touch if we are missing some documents.`,
  "personalia.bedrift.undertittel": `Specify who the submission is for`,
  "personalia.knapp": `Next`,
  "personalia.velgenhet": `Choose NAV-unit`,
  "personalia.sidetittel": `Living situation`,
  "personalia.undertittel": `Specify who the submission is for`,
  "personalia.error.fodselsnummer": `You need to fill in a valid Norwegian national identification number`,
  "personalia.error.name": `Name is required`,
  "personalia.error.adresse": `Address is required`,
  "personalia.error.postnummer": `Postal code is required`,
  "personalia.error.sted": `City is required`,
  "personalia.error.land": `Country is required`,
  "personalia.error.velgkontor": `Please select which NAV-unit the documents shall be sent to`,
  "personalia.error.enhet": `Please select a valid NAV-unit`,
  "personalia.error.velgsituasjon": `You have to choose one of the situations underneath`,
  "personalia.label.fodselsnummer": `Norwegian national identification number`,
  "personalia.label.navn": `Name`,
  "personalia.label.adresse": `Address`,
  "personalia.label.land": `Country`,
  "personalia.label.navkontor": `NAV unit`,
  "personalia.label.postnummer": `Postal code`,
  "personalia.label.poststed": `City`,
  "personalia.label.tidligereKontaktMedNAV": `Check if you have been in contact with NAV about this matter earlier`,
  "personalia.label.velgnavkontor": `Choose NAV unit`,
  "personalia.bedrift.flerepersoner": `More than one person`,
  "personalia.bedrift.ikkeFodselsnummerDnummer": `One person who does not have a Norwegian national identification number`,
  "personalia.bedrift.oppgiFodselsnummerDnummer": `One person who has a Norwegian national identification number`,
  "personalia.bedrift.tiltaksbedrift": `Labor market training, course organizers and other businesses`,
  "personalia.ikkeFodselsnummerDnummer": `I do not have a Norwegian national identification number`,
  "personalia.oppgiFodselsnummerDnummer": `I have a Norwegian national identification number`,
  "personalia.undertekstbold.adresse": `If you have an address you stay at in Norway, use that address. Otherwise, please fill inn your the address you currently reside in.`,
  "personalia.undertekstbold.flerepersoner": `NOTE: If you are going to lay off workers who live in several counties, send the form to the county that most people live in. This choice should not be used for occupational injury reports, sick pay and work-related measures.

In order for your papers to arrive quickly at the right place in NAV, enter which office will handle your case. Do you know that the persons belong to different NAV offices, repeat the procedure for each office you send to. If you are in doubt, you can contact NAV at telephone 55 55 33 36.`,
  "personalia.undertekstbold.gdpr": `This information is used to generate a front page for your application.`,
  "personalia.undertekstbold.tiltaksbedrift": `Example: Applications, invoice, list of participants, or other things that concern work-oriented measures

In order for your papers to arrive quickly at the right place in NAV, enter which office will handle your case. Do you know that the persons belong to different NAV offices, repeat the procedure for each office you send to. If you are in doubt, you can contact NAV at telephone 55 55 33 36.`,
  "klageanke.knapp": `Complaint or Appeal`,
  "klage.tittel.underbanner": `Complaint`,
  "klage.ettersendelse.tittel.underbanner": `Forward documentation to a previously submitted complaint`,
  "klage.videresendt.enhet": `Have you received a letter saying that your case has been transferred to another NAV-unit?`,
  "klage.velg.behandlende.enhet": `Choose the NAV-unit that is handling your case (specified in the letter you have received)`,
  "klage.mellomledd.knapp": "Complaint",
  "klage.mellomledd.tittel": `Complaint`,
  "klage.mellomledd.beskrivelse": `Here you can complain on a decision by NAV`,
  "klage.ettersende": `Do you want to submit documentation to a previously filed complaint?`,
  "anke.tittel.underbanner": `Appeal`,
  "anke.ettersendelse.tittel.underbanner": `Forward documentation to a previously submitted appeal`,
  "anke.mellomledd.knapp": `Appeal`,
  "anke.mellomledd.tittel": `Appeal`,
  "anke.mellomledd.beskrivelse": `Here you can appeal to the National Insurance Court if your complaint was rejected`,
  "anke.ettersende": `Do you want to forward documentation to a previously submitted appeal?`,
  "anke.eller.klage.sidetittel": `Choose complaint or appeal`,
  "anke.eller.klage.meta_desc": `{soknadsnavn}: Complaint or appeal`,
  "ettersendelser.knapp": `Forward`,
  "ettersendelser.tittel.underbanner": `Choose attachments to forward`,
  "ettersendelser.mellomledd.tittel": `Forward documentation`,
  "ettersendelser.mellomledd.soknad.tittel": `Forward to a submitted application`,
  "ettersendelser.mellomledd.soknad.beskrivelse": `Forward documentation to a previously submitted application.`,
  "ettersendelser.mellomledd.soknad.meta_desc": `Submit additional documentation to {soknadsnavn}.`,
  "ettersendelser.mellomledd.soknad.knapp": `Forward on paper`,
  "ettersendelser.mellomledd.digital.knapp": `Forward digitally`,
  "ettersendelser.mellomledd.klageanke.tittel": `Forward to a complaint or an appeal`,
  "ettersendelser.mellomledd.klage.knapp": `Forward to a complaint `,
  "ettersendelser.mellomledd.klage.beskrivelse": `Choose 'Forward to a complaint' if you have submitted a complaint and want to forward additional documentation.`,
  "ettersendelser.mellomledd.anke.knapp": `Forward to an appeal`,
  "ettersendelser.mellomledd.anke.beskrivelse": `Choose 'Forward to an appeal' if you have appealed to the National Insurance Court and want to forward additional documentation.`,
  "sidetittel": `Applications and Forms`,
  "sideingress": `You will find application forms and options for submitting additional documentation by choosing a topic below. The complaint and appeal form is available at nav.no/klage.`,
  "kategori.velg": `Form categories`,
  "kategori.meta_desc": `{kategoritittel}: Submit application forms, additional documentation, or complaint forms.`,
  "kategorier.knapp.bedrift": `Employers`,
  "kategorier.knapp.engelsk": `English`,
  "kategorier.knapp.person": `Person`,
  "underkategori.meta_desc": `{underkategorinavn}: {utlisting}`,
  "stegindikator.steg1": `Type of application`,
  "stegindikator.steg2": `Choose documentation`,
  "stegindikator.steg3": `Later submittion`,
  "stegindikator.steg4": `Living situation`,
  "stegindikator.steg5": `Download and send`,
  "velgvedlegg.informasjonspanel.ingress.bedrift": `When you submit a case to NAV, we might need extra documentation depending on the situation.`,
  "velgvedlegg.informasjonspanel.ingress.person": `When you submit an application to NAV, we might need extra documentation depending on your situation.`,
  "velgvedlegg.informasjonspanel.sporsmal": `Do you need help deciding which documentation to submit?`,
  "velgvedlegg.informasjonspanel.tittel": `Documentation`,
  "velgvedlegg.beskrivelse": `Check off the statements that applies to you`,
  "velgvedlegg.checkbokspanel": `Check the boxes that describe your situation`,
  "velgvedlegg.innholdstittel": `Attachment choice`,
  "velgvedlegg.knapp": `Next`,
  "velgvedlegg.sidetittel": `Select documentation`,
  "velgvedlegg.lesmer.hvaerdette": `What is this?`,
  "velgvedlegg.lesmer.apne": `Read more about this attachment`,
  "velgvedlegg.lesmer.lukk": `Close`,
  "velgvedlegg.ja": "Yes",
  "velgvedlegg.nei": "No",
  "velgvedlegg.vedlegg": "Attachment: ",
  "dinevedlegg.tittel": `Attach these documents to the application`,
  "dinevedlegg.pakrevd": `Required attachment: </br>`,
  "dinevedlegg.sender.na": `I'll send this now`,
  "dinevedlegg.sender.senere": `I'll send this later`,
  "dinevedlegg.ingress.pakrevde": `These documents are required to process your application:`,
  "dinevedlegg.ingress.situasjonsbestemte": `Based on your answers, these documents must be submitted:`,
  "dinevedlegg.beskrivelse": `You can download the application form and attachments on the next page. Send a copy of the documentation and keep the original yourself. Receipts must be originals.`,
  "vissoknadsobjekter.papirsoknader": `Applications by mail`,
  "vissoknadsobjekter.papirsoknader.beskrivelse": `The applications below are used when you do not have an electronic ID. Please note that an application by paper takes longer time to process due to mailing and manual handling of the application papers.`,
  "vissoknadsobjekter.ekspanderbartpanel.tittel": `Submit on paper`,
  "vissoknadsobjekter.innholdstittel": `What do you want to apply for?`,
  "vissoknadsobjekter.relevantinformasjon": `Related information`,
  "vissoknadsobjekter.knapp.soknadpapapir": `Submit on paper`,
  "vissoknadsobjekter.knapp.soknadsdialog": `Submit digitally`,
  "vissoknadsobjekter.knapp.dokumentinnsending": `Submit digitally`,
  "vissoknadsobjekter.knapp.fyllut": `Apply`,
  "vissoknadsobjekter.fyllUt": `Submit by mail`,
  "vissoknadsobjekter.tittel": `Digital application for`,
  "vissoknadsobjekter.ikkeelektroniskID": `Submit on paper`,
  "feil.ingentekst": "Error, did not find text.",
  "veileder.ettersendelse.tittel": "Forward additional documentation",
  "veileder.ettersendelse.steg1": `1. To submit additional documentation for a previously submitted application, you must find the relevant application in the overview below. `,
  "veileder.ettersendelse.steg2": `2. Click the «Forward»-button beside the correct application, and follow the instructions from there.`,
  "veileder.klage.tittel": "Complaint and appeal (Norwegian only)",
  "veileder.klage.steg1": `1. To submit a complaint regarding a decision NAV has made in your case, you have to find the relevant application in the overview below.`,
  "veileder.klage.steg2": `2. Click the «Complaint»-button beside the correct application, and follow further instructions. `,
  "errors.api.fetch": "Ops, something went wrong while fetching data!",
  "errors.api.kategori": `We did not find the chosen category.`,
  "errors.api.underkategori": `We did not find the chosen subcategory.`,
  "errors.api.soknader": `We did not find any applications under this category.`,
  "errors.api.soknadsobjekt": `We did not find the chosen application.`,
  "notFound.tittel": "Ops, something went wrong",
  "notFound.beskrivelse": `The link you clicked may be broken or the page may have been removed.`,
  "notFound.goto.nav": `Go to nav.no`,
  "notFound.goto.soknadsveiviser": `Go to forms and applications`,
  "skjemalenke.lastned": `Download form:`,
  "skjemautlisting.vedleggsskjema": `Vedleggsskjema:`,
  "skjemautlisting.tilveiviser": `Til veiviseren:`,
  "skjemautlisting.lenketil.sed": `SED-skjemaer`,
  "skjemautlisting.lenketil.skjema": `Alle skjemaer`,
  "skjemautlisting.lenketil.detaljert": `Søknader og skjemaer listet ut etter visning`,
  "skjemautlisting.detaljert.forklaring": `Bruk denne lista hvis du raskt vil finne ut hvor et skjema er publisert. Søknader som ikke har skjemaer vises bare i denne lista.`,
  "skjemautlisting.sed.forklaring": `<p>Denne siden inneholder en oversikt over de SED (Strukturerte Elektroniske Dokumenter) som skal benyttes i utveksling av trygdeopplysninger med EU/EØS-land i henhold til forordning 883/2004 og 987/2009. SED kan kun sendes til kompetente institusjoner i det enkelte EU/EØS-land. De kompetente institusjonene finnes i EUs <a class="lenke" href="https://ec.europa.eu/employment_social/social-security-directory/welcome?langId=no">institusjonskatalog</a>.</p>

<p>For hver SED finnes det også forklaringer i et eget dokument med samme nummer som skjemaet etterfulgt av - (bindestrek).</p>

<p>Innledningsvis under hvert fagområde er det en oversikt over alle flyter (og SED) som kan benyttes og en korrelasjonstabell som viser sammenhengen mellom tidligere E-skjemaer og de nye SED.</p>`,
  skjemautlisting: `Skjemautlisting`,
  "skjemautlisting.tabell.alleskjemaer": `Alle skjemaer`,
  "skjemautlisting.tabell.alle": `Alle`,
  "skjemautlisting.tabell.velgskjemaerfor": `Velg skjemaer for:`,
  "skjemautlisting.tabell.alleomrader": `Alle områder`,
  "skjemautlisting.tabell.soketterskjema": `Søk etter skjema:`,
  "skjemautlisting.tabell.skjemautenemneord": `Skjemaer uten emneord`,
  "skjemautlisting.tabell.skjemanummer": `ID`,
  "skjemautlisting.tabell.skjemanavn": `Skjemanavn`,
  "skjemautlisting.tabell.malgruppe": `Målgruppe`,
  "skjemautlisting.tabell.pdf": `Filer`,
  "vedleggsvalg.toggle.veiledning": `Yes`,
  "vedleggsvalg.toggle.ikkeveiledning": `No`,
  "vedleggsvalg.toggle.info.ja": `When you have answered the questions below, the documentation to submit with your application will be listed.`,
  "vedleggsvalg.toggle.info.nei": `Choose which documentation you will be submitting with you application. If you do not want to submit documentation, you can proceed to the next step; "Specify who the submission is for"`,
  "sjekkbokser.pakrevde": `Required documentation for all applications`,
  "sjekkbokser.situasjonsbestemte": `Required documentation based on the situation`
}
